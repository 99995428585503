// 菜单2品牌
<template>
  <div class="main">
    <div class="top">
      <div class="top-title">ALY HOME CASE</div>
      <div class="top-text">
        Here are the real cases of the homes of the customers we have served
        before. You can use them as references to choose products.
      </div>
      <div class="content-linex"></div>
    </div>

    <!-- <div class="content">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">ALY HOME Brands</div>
      </div>
      <div class="content-text">
        At ALY HOME, we are proud to offer you our own product line, which comes
        from our production base in Ningbo, China. Our ranges of wooden blinds,
        faux wood blinds, roller blinds and curtain poles have been carefully
        curated to be the best in the industry. Crafted with care and attention,
        we ensure that we provide only the highest-quality products at prices
        you'll love.
      </div>
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/bra_icon_logo@2x.png"
        class="content-img"
      />
    </div> -->

    <!-- <div class="content">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">ALY HOME CASE</div>
      </div>
      <div class="content-text">
        Here are the real cases of the homes of the customers we have served
        before. You can use them as references to choose products.
      </div>
    </div> -->
    <div class="content">
      <div class="second-list">
        <div
          class="second-item"
          v-for="(v, i) in ctIdList"
          :key="i"
          :class="{ 'second-item-active': ctId == v.ctId }"
          @click="selectItem(v.ctId)"
        >
          <img
            :src="`${ossPrefix}${v.icon}`"
            class="child-icon"
            v-if="i != 0"
          />
          {{ v.name }}
        </div>
      </div>
      <div class="case-list" v-if="caseList.length > 0">
        <img
          v-for="(v, i) in caseList"
          :key="i"
          @click="toPreviewImg(i)"
          :src="`${ossPrefix}${v.fileCover}`"
          class="case-item-pic"
        />
        <!-- <div class="more" @click="addCurrentPage()" v-if="hasMoreItems">
          <div>View more</div>
          <i class="el-icon-bottom more-icon"></i>
        </div> -->
      </div>
      <div class="no-list" v-else>
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/prod_icon_default@2x.png"
          class="no-list-pic"
        />
      </div>
      <!-- <div class="more" v-if="!hasMoreItems && caseList.length > 0">
        <div>There's nothing more left</div>
      </div> -->
    </div>
    <div class="content">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">ALY HOME Brands</div>
      </div>
      <div class="content-text">
        We work closely with leading names in the interiors industry to meet
        your needs and bring you the finest window furnishings available. Our
        partners are known and respected for their quality and products.
        Combined with ALY HOME's excellent service, you can be confident in
        finding the perfect solution for any of your window furnishing needs.
      </div>
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/bra_icon_logo2@2x.png"
        class="content-img"
      />
    </div>
    <image-preview-modal
      v-if="isModalVisible"
      :images="imgsList"
      :currentIndex="currentIdx"
      :isVisible="isModalVisible"
      @close="closeModal"
    />
  </div>
</template>
<script>
import {
  usVisitLogInsert,
  usCaseTypeList,
  usCaseFileList,
} from "@/api/home.js";
import ImagePreviewModal from "@/components/ImagePreviewModal.vue";

export default {
  name: "Brands",
  components: { ImagePreviewModal },
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      currentPage: 1,
      pageSize: 12,
      totalPage: 0,
      ctId: "",
      ctIdList: [],
      caseList: [],
      isModalVisible: false,
      currentIdx: 0,
      imgsList: [],
    };
  },
  activated() {
    this.ctId = "";
    this.getCaseTypeList();
    this.addVisitLogInsert();
  },
  methods: {
    toPreviewImg(index) {
      let list = [];
      this.caseList.forEach((v) => {
        list.push(`${this.ossPrefix}${v.fileCover}`);
      });
      this.imgsList = list;
      this.currentIdx = index;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    selectItem(id) {
      this.ctId = id;
      this.getData();
    },
    // 向后端请求分类列表数据
    getCaseTypeList() {
      usCaseTypeList().then((res) => {
        if (res.code == 1) {
          const val1 = {
            ctId: "",
            name: "ALL",
          };
          const cate = res.data.dataList;
          cate.unshift(val1);
          this.ctIdList = cate;
          this.getData();
        } else {
          const val1 = {
            ctId: "",
            name: "ALL",
          };
          const cate = [];
          cate.unshift(val1);
          this.ctIdList = cate;
          this.getData();
          this.$message.error(res.message);
        }
      });
    },
    // 向后端请求全部商品或分类商品数据
    getData() {
      // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
      usCaseFileList(this.ctId).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.caseList = [...res.data.dataList];
        } else {
          this.caseList = [];
          this.$message.error(res.message);
        }
      });
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Case";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1110px;
  margin: 58px auto;
}
.top-title {
  color: #293744;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
}
.top-text {
  color: #293744;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 21px;
}
.content {
  width: 1110px;
  margin: 64px auto;
}
.content-liney {
  width: 1px;
  height: 60px;
  background-color: #293744;
}
.content-linex {
  width: 1110px;
  height: 2px;
  background-color: #cccccc;
  margin-top: 41px;
}

.content-top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 38px;
}
.content-title {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin-left: 16px;
}
.content-text {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 57px;
}
.content-img {
  width: 1110px;
}
.content {
  width: 1110px;
  margin: 0 auto 21px;
  font-size: 16px;
  color: #262626;
}
.second-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  box-sizing: border-box;
  white-space: nowrap;
}
.second-item {
  display: flex;
  align-items: center;
  margin: 0 36px 25px 0;
  font-size: 24px;
  cursor: pointer;
}
.second-item-active {
  font-weight: bold;
  color: #caa68a;
}
.case-list {
  width: 100%;
  margin: 20px auto 35px;
  display: flex;
  flex-wrap: wrap;
}

.case-item {
  width: 380px;
  height: 380px;
  margin: 25px 30px 25px 0;
  border-radius: 20px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.case-list :nth-child(3n) {
  margin-right: 0;
}
.case-item-pic {
  width: 355px;
  height: 355px;
  margin: 0 22.5px 35px 0;
  cursor: pointer;
}
.more {
  padding: 0;
  text-align: center;
  margin: 0 auto 179px;
  color: #74c9c6;
  font-size: 30px;
  cursor: pointer;
}
.more-icon {
  margin: 0 auto;
  color: #74c9c6;
  font-size: 30px;
  cursor: pointer;
}
.no-list {
  width: 100%;
  height: 500px;
  margin: 0 auto 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #cccccc;
  font-size: 26px;
}
.no-list-pic {
  width: 466px;
  height: 466px;
}
.child-icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
</style>
